<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-input-search v-model="searchForm.search" placeholder="检验单号" allowClear @search="search" />
      </a-col>
    </a-row>

    <a-row style="margin-top: 12px">
      <a-table
        size="small"
        :columns="columns"
        :dataSource="items"
        rowKey="id"
        :loading="loading"
        :pagination="pagination"
        @change="tableChange"
      >
        <div slot="action" slot-scope="value, item">
          <a-button-group size="small">
            <a-button type="primary" size="small" @click="receipt(item)">检验</a-button>
          </a-button-group>
        </div>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import { arrearsReceivableList } from "@/api/finance";

export default {
  name: "SaleRecord",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "检验单号",
          dataIndex: "number",
        },
        {
          title: "产品编号",
          dataIndex: "product_number",
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
        },
        {
          title: "待检数量",
          dataIndex: "remain_quantity",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { page: 1, has_arrears: true, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [
        {
          id: 1,
          number: "CGRK2402210001",
          product_number: "P0000000001",
          product_name: "电脑",
          remain_quantity: 900,
          remark: "",
        },
      ],
      visible: false,
      targetItem: {},
      form: {},
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },
    list() {
      // this.loading = true;
      // arrearsReceivableList(this.searchForm)
      //   .then((data) => {
      //     this.pagination.total = data.count;
      //     this.items = data.results;
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    receipt(item) {
      this.$router.push({ path: "/finance/collection_create", query: { client: item.id } });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
